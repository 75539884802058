import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import { useInView } from 'react-intersection-observer';

import { MdArrowBack, MdArrowForward } from 'react-icons/md';

const imgPanelSize = 45 // percent of horizontal viewport
const imgPanelMargin = 7.5
const translateUnit = imgPanelSize + imgPanelMargin

// Refactor to useReducer to handle carousel logic
// add keypress
    // intersection observer + arrow key press 

const ProjectPreview = () => {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 1920) {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      allMarkdownRemark(
        filter: {
          frontmatter: {
            featured: {
              eq: true
            }
          }
        }, 
        sort: {
          fields: [frontmatter___date],
          order: DESC
        }
      ) {
        edges {
          node {
            frontmatter {
              path
              title
              miniDescription
              images
            }
          }
        }
      }
    }
  `);

  const { allMarkdownRemark, allImageSharp } = data;

  // currentImage: currently centered image: allows hovering of this image by toggling hover classf
  const [currentImage, setCurrentImage] = useState(0);

  const images = allMarkdownRemark.edges.map((edge, index) => {
    const { title, miniDescription, images, path } = edge.node.frontmatter
      return (
        <Link
          key={index}
          to={path}
          style={{ marginRight: `${imgPanelMargin}vw` }}
        >
          <div
            style={{ width: `${imgPanelSize}vw` }}
            className={`project-panel-img-container ${
              index === currentImage
                ? "project-panel-img-container-hoverable"
                : ""
            }`}
          >
            <div className="project-panel-hover-zoom">
              <div className="project-panel-img-card">
                <h3>{title}</h3>
                <p>{miniDescription}</p>
              </div>
              <Img
                style={{maxHeight: 560}}
                fluid={
                  allImageSharp.edges.filter(
                    x => x.node.fluid.originalName === images[0]
                  )[0].node.fluid
                }
              />
            </div>
          </div>
        </Link>
      )
  })

  // currentPos: total shift of entire carousel in vw
  const [currentPos, setCurrentPos] = useState(translateUnit * images.length * -1 + translateUnit / 2)
  // currentPanel: 
  const [mainPanelActive, setMainPanelActive] = useState(true);
  const [panelTransforms, setPanelTransforms] = useState({one: 0, two: 0})
  const [lastDirection, setLastDirection] = useState(null);
  const [otherPanelSide, setOtherPanelSide] = useState('left');
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  useEffect(() => {
    let mainPanel = mainPanelActive;
    let panelSide = otherPanelSide;
    let change = false;
    // handle mainPanelActive switching
    if ((currentImage === 0 && lastDirection === 1) ||
      (currentImage === images.length - 1 && lastDirection === -1)) {
      setMainPanelActive(state => !state)
      change = true
      if (panelSide === 'left') {
        panelSide = 'right'
      } else {
        panelSide = 'left'
      }
    }

    // handle panel moving
    if (lastDirection !== null) {
      if (currentImage === images.length - 1) {
        if (mainPanel && panelSide === 'left') {
          change = false
          setPanelTransforms(state => {
            return {...panelTransforms, one: state.one + translateUnit * images.length * 2}
          })
          setOtherPanelSide('right')
        } else if (!mainPanel && panelSide === 'left') {
          change = false
          setPanelTransforms(state => {
            return {...panelTransforms, two: state.two + translateUnit * images.length * 2}
          })
        }
        setOtherPanelSide('right') 
      } else if (currentImage === 0) {
        if (mainPanel && panelSide === 'right') {
          change = false
          setPanelTransforms(state => {
            return { ...panelTransforms, one: state.one - translateUnit * images.length * 2 }
          })
          setOtherPanelSide('left') 
        } else if (!mainPanel && panelSide === 'right') {
          change = false
          setPanelTransforms(state => {
            return { ...panelTransforms, two: state.two - translateUnit * images.length * 2 }
          })
          setOtherPanelSide('left') 
        }
      }

      if (change) {
        setOtherPanelSide(panelSide)
      }
    }

  }, [currentImage])
  return (
    <>
      <div 
        ref={ref} style={inView ? {opacity: 1, transition: 'opacity 500ms linear'} : {opacity: 0, 
        transition: 'opacity 500ms linear'}}
      >
        <div className="x-center carousel-title">
          <h1>Featured Projects</h1>
        </div>
        <div className="carousel-container">
          <div className="project-panel-arrow-container">
            <div
              onClick={() => {
                setLastDirection(-1)
                setCurrentPos(state => state + translateUnit)
                setTimeout(() => {
                  setCurrentImage(state => {
                    if (state > 0) {
                      return state - 1
                    } else {
                      return images.length - 1
                    }
                  })
                }, 50)
              }}
              className="project-panel-arrow-button x-y-center-no-width"
            >
              <MdArrowBack className="arrow" />
            </div>
            <div
              onClick={() => {
                setLastDirection(1)
                setCurrentPos(state => state - translateUnit)
                setTimeout(() => {
                  setCurrentImage(state => (state + 1) % images.length)
                }, 50)
              }}
              className="project-panel-arrow-button x-y-center-no-width"
            >
              <MdArrowForward className="arrow" />
            </div>
          </div>
          <div
            className="project-carousel flex-row"
            style={{
              transform: `translateX(${currentPos}vw)`,
            }}
          >
            <div
              className="flex-row"
              style={{ transform: `translateX(${panelTransforms.one}vw)` }}
            >
              {images}
            </div>
            <div
              className="flex-row"
              style={{ transform: `translateX(${panelTransforms.two}vw)` }}
            >
              {images}
            </div>
          </div>
        </div>
        <div className="x-center carousel-slider-container">
          <div className="carousel-slider-left-number">
            {"0" + (currentImage + 1)}
          </div>
          <div className="x-y-center carousel-slider-blocks">
            {images.map((image, index) => (
              <div
                key={index}
                className={`carousel-slider-block ${
                  index === currentImage
                    ? "carousel-slider-block-highlighted"
                    : ""
                }`}
              ></div>
            ))}
          </div>
          <div className="carousel-slider-right-number">
            {"0" + images.length}
          </div>
        </div>
      </div>
  </>)
}

export default ProjectPreview
