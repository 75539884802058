import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"
import anime from 'animejs/lib/anime.es.js';
import { useInView } from 'react-intersection-observer';

import Layout from "../components/layout";
import SEO from "../components/seo";
import ProjectPreview from "../components/project-preview";
import CtaButton from "../components/cta-button";

import ConstructionRot from "../images/testdraftpathsrot.svg";

const IndexPage = ({ location }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [ref, inView, entry] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  const images = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hero-images/hero33.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      draft1: file(relativePath: { eq: "project-photos/livingroom2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      draft2: file(relativePath: { eq: "project-photos/livingroom3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  useEffect(() => {
    setIsLoaded(true);
    anime({
      targets: '.hero-content',
      opacity: [0, 1],
      duration: 1500,
      easing: 'easeInOutSine',
      delay: 600,
    })
    anime({
      targets: '.hero-content div h1',
      opacity: [0, 1],
      duration: 1000,
      easing: 'easeInOutSine',
      delay: 1350
    })
    anime({
      targets: '.hero-content div h3',
      opacity: [0, 1],
      duration: 1000,
      easing: 'easeInOutSine',
      delay: 2100,
    })
  }, [])

  useEffect(() => {
    if (inView) {
      anime({
        targets: ".floorplan-draft path",
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: "easeInOutQuad",
        duration: 1000,
        delay: (el, i) => 500 + i * 20,
        direction: "normal",
      })
      anime({
        targets: ".floorplan-draft",
        opacity: [0, 0.7],

        duration: 1500,
        direction: "normal",
      })
      anime({
        targets: ".draft-overlay-image",
        opacity: [0, 0.925],
        duration: 1500,
        easing: "easeInOutQuad",
        delay: (el, i) => 15000 + i * 500,
      })
      anime({
        targets: ".draft-panel-text",
        opacity: [0, 1],
        translateX: [-40, 0],
        duration: 1000,
        easing: "easeInOutQuad",
        delay: (el, i) => 300 + i * 700,
      })
    }
  }, [inView])

  return (
    <Layout location={location}>
      <SEO title="Home" />
      <section className="hero-container">
        <Img
          className={`hero-image ${isLoaded === true ? 'image-zoom' : null}`}
          objectFit="cover"
          objectPosition="center center"
          fluid={images.hero.childImageSharp.fluid}
        />
        <div className="hero-content">
          <div>
            <h1>Renno Specialists</h1>
            <h3>
              Your single supplier for multi-family interior and exterior new
              construction and renovations.
            </h3>
          </div>
        </div>
      </section>
      <section className="home-panel flex-center">
        <div className="container">
          <div
            className="flex-row"
            style={{ width: "100%", marginBottom: 40}}
          >
            <div
              style={{
                flex: "1 0 50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginRight: '2rem',
              }}
              className="draft-panel-copy"
            >
              <h2 className="draft-panel-text" >
                Renno Group offers multifamily renovation and new construction
                developments using a modern approach.
              </h2>
              <p
                className="draft-panel-text"
              >
                Our commitment is to offer competitive pricing while also
                supplying quality material and workmanship. Our decades of
                construction experience fully equips us to turn your renovation
                dream into a reality.
              </p>
              <p
                className="draft-panel-text"
              >
                We start with your goal, and through strategic planning, strive to
                meet every expectation from concept to completion.
              </p>
            </div>
            <div ref={ref} style={{ flex: "1 0 50%", position: "relative" }}>
              <ConstructionRot className="floorplan-draft" />
            </div>
          </div>
        </div>
      </section>
      <section>
        <ProjectPreview />
      </section>
      <div style={{ marginBottom: 60 }}></div>
    </Layout>
  )
}

export default IndexPage
